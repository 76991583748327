/** @format */

import React from "react";
import { Button } from "react-bootstrap";

function Boton({ children, onClick }) {
  return (
    <Button
      variant="light"
      style={{
        backgroundColor: "orange",
        color: "white",
        paddingLeft: 30,
        paddingRight: 30,
        borderRadius: 10,
        borderWidth: 1,
        borderStyle: "solid",
      }}
      className="remove-btn-focus"
      onClick={onClick}
    >
      {children}
    </Button>
  );
}

export default Boton;
