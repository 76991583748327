/** @format */

import React, { useContext } from "react";
import Layout from "../../components/Layout";
import Boton from "../../components/Boton";
import chekIcon from "../../images/check.svg";
import "./pago-completado.css";
import { navigate } from "gatsby-link";
import { AppContext } from "../../utils/ContextWrapper";

function PagoCompletado() {
  const context = useContext(AppContext);

  const handleClick = () => {
    navigate("/");
  };

  return (
    <Layout>
      <div className="pago-completado-container">
        <div className="pago-completado-formulario">
          <img src={chekIcon} alt="chek icon" width={80} />
          TU PAGO SE HA COMPLETADO EXITOSAMENTE
        </div>
        <br />
        <div>Tu código de referencia es:</div>
        <div>
          <span className="pago-completado-codigo">{context.store.aprobacion}</span>
        </div>

        <span>¡Muy pronto estarás disfrutando de nuestros productos!</span>
        <br />
        <Boton onClick={handleClick}>Continuar</Boton>
      </div>
    </Layout>
  );
}

export default PagoCompletado;
